import React, { useContext, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { COMPLIANCE_POLICIES, MARKETS } from '../../global/constants';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { useLocales } from '../../global/custom-hooks/useLocales';
import { handleHeadlessLink } from '../../global/utils/handleHeadlessLink';
import { useGetHeadlessLoginConfigQuery } from '../../services/headless/headlessApi';
import { HeadlessPageType } from '../../services/headless/types';

const CompliancePack = () => {
  const {
    i18n: { language }
  } = useTranslation();
  const { locales } = useLocales();
  const { country, compliancePackProjectId, hostEnv } = useContext(ConfigContext);
  const isMalaysia = country?.toLowerCase() === MARKETS.malaysia;

  useLayoutEffect(() => {
    if (language) {
      const element = document.getElementById('cbgCConsent');
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  }, [language]);

  const { data: headlessData } = useGetHeadlessLoginConfigQuery({
    languageCode: language
  });

  if (!headlessData || headlessData?.meta?.status !== 200) {
    return null;
  }

  const { links } = headlessData?.route;

  if (!country) {
    return null;
  }

  if (!language && !locales.length) {
    return null;
  }

  if (country && !compliancePackProjectId) {
    console.error('Please set compliancePackProjectId in global config');
    return null;
  }

  const extractHeadlessLink = (pageType: HeadlessPageType) => {
    const complianceLink = links.find((foundLink) => foundLink.pageType === pageType);
    return handleHeadlessLink(complianceLink?.url);
  };

  return (
    <Helmet>
      <script
        async
        id="cbgCompPack"
        data-testid="cbgCompPack"
        src="https://ct-compliance-pack.tst.ct-apps.io/cbgCompPack.js"
        data-gtm-id="GTM-P6DRX9V"
        data-market={country.toLowerCase()}
        data-verify-age={isMalaysia}
        data-prj-id={compliancePackProjectId}
        data-culture={language}
        data-env={hostEnv || 'live'}
        data-cookies-page={extractHeadlessLink(HeadlessPageType.COOKIE_POLICY) || COMPLIANCE_POLICIES.COOKIE}
        data-policy-page={extractHeadlessLink(HeadlessPageType.PRIVACY_POLICY) || COMPLIANCE_POLICIES.PRIVACY}
      />
    </Helmet>
  );
};

export { CompliancePack };
